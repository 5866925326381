@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply mx-auto max-w-screen-xl w-11/12;
  }

  .btn {
    @apply block w-full cursor-pointer rounded-md px-5 py-3 uppercase text-white text-center font-bold leading-none tracking-wide bg-primary border-2 border-primary hover:bg-primary-dark hover:border-primary-dark disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .form-body {
    @apply flex flex-col space-y-8;
  }

  .input-label {
    @apply font-bold text-sm leading-tight mb-2.5;
  }

  .input-text {
    @apply leading-none rounded block w-full p-2.5 border text-lg border-gray-600 placeholder-gray-600 text-black focus:outline-none focus:ring-primary focus:border-primary focus:z-10;
  }

  .page-header {
    @apply container text-3xl pt-15 font-normal text-left md:text-6xl md:pt-24;
  }

  .error-message {
    @apply bg-red-200 border border-red-600 text-red-600 text-center p-5;
  }

  .autocomplete-menu {
    @apply absolute w-full bg-white border border-gray-600 z-50 shadow-search top-12 rounded;
  }

  /* JOB SEARCH COMPONENT */

  .job-search .input-text {
    @apply border-0 text-2xl leading-none p-0;
  }

  .job-search .input-label {
    @apply mb-2;
  }

  .job-search .autocomplete-menu {
    @apply top-10;
  }

  /* TRIX EDITOR */

  .trix-content ul {
    @apply list-disc pl-10;
  }

  .trix-content ol {
    @apply list-decimal pl-10;
  }

  .trix-content a {
    @apply text-blue-500 hover:underline;
  }

  trix-toolbar {
    @apply sticky bg-white top-0 pt-2.5 px-2;
  }

  .trix-button-group--file-tools {
    @apply hidden !important;
  }

  /* PROGRESS BAR */

  #nprogress {
    @apply pointer-events-none;
  }

  #nprogress .bar {
    @apply bg-primary fixed z-50 top-0 left-0 w-full h-0.5;
  }

  #nprogress .peg {
    @apply block absolute right-0 max-w-sm h-full shadow-lg opacity-100 rotate-3 translate-y-7;
  }
}
